<template>
  <div class="role-list-wrap">
    <p class="users"><b>{{ $t('Roles') }}</b></p>

    <div class="search-actions-wrap">
      <div class="search" v-if="hasPermission('role_view')">
        <div class="md-layout-item">
          <md-field>
            <img src="../../assets/images/search.svg" alt="search" class="search-select">
            <md-input v-model="roleName" :placeholder="$t('Name')"/>
          </md-field>
        </div>
      </div>

    </div>
    <md-table v-if="hasPermission('roles_view')" v-model="roles" md-card md-sort="name" md-sort-order="asc"
              md-fixed-header>
      <md-table-row slot="md-table-row" slot-scope="{ item }" :md-disabled="!hasPermission('roles_delete')"
      >
        <md-table-cell :md-label="$t('Name')" md-sort-by="name">{{ $t(item.name) }}</md-table-cell>
        <md-table-cell :md-label="$t('Created at')" md-sort-by="created_at">{{ item.created_at }}</md-table-cell>
        <md-table-cell :md-label="$t('Updated at')" md-sort-by="updated_at">{{ item.updated_at }}</md-table-cell>
        <md-table-cell :md-label="$t('Actions')" v-if="hasPermission('roles_update')">
          <router-link
              :to="{ name: 'roles-edit', params: { id: item.id }}"
              class="roles-update"
          >
            <md-button class="md-dense md-raised" v-if="hasPermission('roles_update')">
              <md-icon>edit</md-icon>
            </md-button>
          </router-link>

        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
import {userPermissions} from '@/mixins/userPermissions';

export default {
  name: 'roles-list',
  data: () => ({
    listUrl: process.env.VUE_APP_API_URL + "/roles/list",
    roles: [],
    filteredRoles: [],
    roleName: '',
    selected: [],
  }),

  mixins: [
    userPermissions
  ],

  watch: {
    roleName: function (val) {
      if (!val) {
        this.roles = this.filteredRoles;
        return;
      }
      const filter = this.roles.filter(role => {
        return role.name.toLowerCase().includes(val.toLowerCase())
      });

      if (val === '') {
        return this.roles
      }
      this.roles = filter
    },
  },
  mounted() {
    this.$axios
        .get(this.listUrl)
        .then(response => {
          this.setRolesList(response.data.data)
        })
        .catch(error => console.log(error));
  },
  methods: {
    onSelect(items) {
      let itemIds = [];
      items.map(item => {
        itemIds.push(item.id);
      });
      this.selected = itemIds.filter((item, pos) => {
        return itemIds.indexOf(item) === pos;
      });
    },
    setRolesList(data) {
      this.roles = data;
      this.filteredRoles = JSON.parse(JSON.stringify(this.roles));

    },

    hasPermission(name) {
      return this.userPermissions.indexOf(name) >= 0
    },

    axiosHandler(id) {
      this.$axios
          .delete(process.env.VUE_APP_API_URL + "/roles/" + id + "/delete")
          .then(() => {
            this.roles = this.roles.filter(el => el.id !== id)
          })
          .catch(error => console.log(error))
    },
    async handlerDelete(id = null) {
      let conf = confirm("Are you sure?");

      if (!conf) {
        return;
      }

      if (id != null) {
        this.axiosHandler(id)
      } else {
        let ids = this.selected;
        let actions = [];

        ids.map(id => {
          actions.push(
              this.axiosHandler(id)
          )
        });
        try {
          await Promise.all(actions)
        } catch (e) {
          console.log(e)
        }
      }

    },
  },

}
</script>

<style lang="scss">
.role-list-wrap {
  padding: 0 35px 0 21px;

  .md-input {
    padding-left: 50px !important;
    position: absolute;
    top: 8px;

  }

  .roles-update {
    padding-right: 30px;
  }

  .users {
    margin: 0 0 16px;
    font-weight: 900;
    font-size: 36px;
    line-height: 52px;
    color: #000000;
  }

  .md-table-sortable-icon {
    opacity: 1 !important;

    svg {
      fill: #EC8B31 !important;
      height: 18px !important;
    }
  }

  .search-actions-wrap {
    display: flex;
    justify-content: space-between;
  }

  .actions {
    display: flex;
  }


  .md-button {
    border: 1px solid #CDD0D9;
    box-sizing: border-box;
    border-radius: 6px;
    width: 36px !important;
    height: 36px;
    min-width: unset;
  }

  .md-button.md-theme-default.md-raised:not([disabled]).md-primary .md-icon-font, .md-button.md-theme-default.md-raised:not([disabled]).md-accent .md-icon-font {
    color: #7f7878 !important;
    background-color: white;
  }

  .md-button.md-theme-default.md-raised:not([disabled]).md-accent {
    background-color: white;
  }

  .btn-create {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 158px;
    background: #FFFFFF;
    border: 1px solid #CDD0D9;
    border-radius: 6px;
    margin-left: 9px;

    p {
      margin: 0;
      padding: 12px;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #000000;
    }

    img {
      margin-right: 15px;
    }
  }

  .btn-delete {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    width: 158px;
    border: 1px solid #CDD0D9;
    border-radius: 6px;

    p {
      margin: 0;
      padding: 12px;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #000000;
    }

    img {
      margin-left: 15px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .btn-delete:hover, .btn-create:hover {
    background: #F7F7F8;
  }

  .md-table {
    margin-top: 30px;
    box-shadow: unset !important;
  }

  .md-table + .md-table {
    margin-top: 16px
  }

  .search {
    display: flex;

  }

  .md-layout-item {
    flex: unset;
    height: 48px;
  }

  .md-field {
    width: 289px;
    background: #FFFFFF;
    border: 1px solid #CDD0D9;
    box-sizing: border-box;
    border-radius: 4px;
    margin-top: 0;

    &::after {
      background-color: unset !important;
    }
  }

  .md-input {
    height: unset !important;
  }

  .md-table-row {
    background: unset;
  }

  .md-table.md-theme-default .md-table-content, .md-table.md-theme-default .md-table-alternate-header {
    height: unset !important;
    max-height: unset !important;
  }

  .md-select {
    .md-icon-image {
      position: absolute;
      right: 0;
      left: 75%;
      top: 13px;
    }
  }

  .search-select {
    position: absolute;
    left: 15px;
  }

  .md-checkbox.md-theme-default.md-checked .md-checkbox-container {
    background-color: #054F63 !important;
  }

  .md-ripple, .md-table-cell-container {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 32px;
    color: #000000;
    text-align: left;
  }

  .md-table-fixed-header-container > table > thead > tr {
    border-bottom: 1px solid #000000;;
  }

  .md-checkbox .md-checkbox-container {
    border: 1px solid #CDD0D9 !important;
    box-sizing: border-box !important;
    border-radius: 6px !important;
  }

  .md-table-cell-selection .md-checkbox {
    margin: 0 47px 0 37px;
  }

  .md-table-cell-selection .md-checkbox .md-checkbox-container:after {
    top: 0 !important;
    left: 5px !important;
  }

  .md-table-cell {
    &:last-child .md-table-cell-container {
      display: flex;
    }
  }

  .md-table-head.md-sortable .md-table-sortable-icon, .md-table-head.md-table-cell-selection + .md-sortable .md-table-sortable-icon {
    right: 8px;
    left: auto;
  }

  .md-table-head:last-child {
    .md-table-head-container {
      text-align: right;
      padding-right: 22px;
    }
  }

  .md-table-cell:last-child {
    .md-table-cell-container {
      display: flex;
      justify-content: flex-end;
    }
  }

}
</style>
<style lang="scss">
.md-table-head-label {
  font-weight: bold !important;
  height: 28px !important;
  padding-right: 32px !important;
  padding-left: 24px !important;
  display: inline-block;
  position: relative;
  line-height: 28px;
}
</style>
